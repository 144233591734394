import React, { useEffect, useState } from "react";
import {
  LoginWrapper,
  Container,
  StyledForm,
  BodyContent,
  ImgLogo,
  ImageHolder,
  FormHeading,
  FormHeadingBox,
  FormSubHeading,
  FormInput,
  SignInBtn,
  // SignUpText,
  // SignUpLink,
  AccountListBox,
  AccountList,
  LHSBox,
  ArrowRight,
  Badge,
  AccountTitle,
  AccountSubTitle,
  CompanyCodeBox,
  CompanyHeading,
  CompanySubHeading,
  FormItem,
  EmptyListBox,
  EmptyText,
} from "./style";
import iconLogo from "assets/images/website-logo.svg";
import { tostor } from "components/helpers";
import { useNavigate } from "react-router-dom";
import { isValidWorkEmail, isValidEmail } from "utils";
import { tenantService } from "services/tenantService";

function Login() {
  let navigate = useNavigate();
  const [form] = StyledForm.useForm();
  const [screen, setScreen] = useState(0);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [tenantList, setTenantList] = useState({});
  const handleSubmit = (values) => {
    if (screen === 0) {
      tenantService
        .getTenantByEmail(values.Email)
        ?.then((tenants) => {
          setTenantList(tenants);
          setScreen(screen + 1);
        })
        ?.catch((error) => {
          tostor.error(error.message);
        });
    } else if (screen === 1) {
      tenantService
        .getTenant(values.code)
        .then((tenant) => {
          if (tenant?.length > 0) {
            setTenantList(tenant);
          } else {
            tostor.error("Invalid Company Code");
          }
        })
        .catch((error) => {
          tostor.error(error.message);
        });
    }
  };
  const handleSelectedAccount = (state, id, data) => {
    if (state) {
      setSelectedAccount({ id, data });
      let localData = {
        ...data,
        emailId: form.getFieldValue("Email"),
      };

      localStorage.removeItem("selectedAccount");
      localStorage.setItem("selectedAccount", JSON.stringify(localData));

      navigate("/tenant/login");
    }
  };
  const GetEmailField = () => (
    <StyledForm.Item
      name="Email"
      label="Email"
      validateTrigger={["onSubmit"]}
      rules={[
        {
          required: true,
          message: "Email is required",
        },
        {
          validator: (_, value) => {
            if (!value || (isValidEmail(value) && isValidWorkEmail(value))) {
              return Promise.resolve();
            } else if (!isValidWorkEmail(value)) {
              return Promise.reject("Please enter work email");
            } else {
              return Promise.reject("Email is not a valid email!");
            }
          },
        },
      ]}
      hasFeedback
    >
      <FormInput placeholder="Enter your email" />
    </StyledForm.Item>
  );
  const getText = (screen) => {
    switch (screen) {
      case 0:
        return {
          heading: "Log in to your account",
          subHeading: "Welcome back! Please enter your details.",
        };
      case 1:
        return {
          heading: "Select account",
          subHeading: "",
        };
      default:
        return {
          heading: "Log in to your account",
          subHeading: "Welcome back! Please enter your details.",
        };
    }
  };

  const data = getText(screen);

  // const SignUpLinkComponent = () => (
  //   <SignUpText variant="textSM" weight="l400" className="text-center">
  //     Don't have an account?{" "}
  //     <SignUpLink
  //       variant="routelink1"
  //       weight="l500"
  //       to={"/"}
  //       className="text-decoration form-footer-subtext"
  //     >
  //       Sign up
  //     </SignUpLink>
  //   </SignUpText>
  // );

  useEffect(() => {
    localStorage.clear();
  }, []);

  return (
    <LoginWrapper>
      <Container>
        <BodyContent>
          <ImageHolder>
            <ImgLogo src={iconLogo} />
          </ImageHolder>
          <FormHeadingBox>
            {data?.heading && (
              <FormHeading weight="a900" variant="displaySM">
                {data?.heading}
              </FormHeading>
            )}
            {data?.subHeading && (
              <FormSubHeading variant="textMD" weight="l400">
                {data?.subHeading}
              </FormSubHeading>
            )}
          </FormHeadingBox>
          {screen === 0 && (
            <StyledForm
              layout="vertical"
              labelCol={{
                span: 10,
              }}
              wrapperCol={{
                span: 24,
              }}
              name="control-hooks"
              onFinish={handleSubmit}
              requiredMark={false}
              size={"large"}
              form={form}
            >
              <GetEmailField />
              <FormItem mb="32px">
                <SignInBtn
                  buttonType="submit"
                  variant="primary"
                  btnLabel="Next"
                />
              </FormItem>
              {/* <SignUpLinkComponent /> */}
            </StyledForm>
          )}
          {screen === 1 && (
            <>
              {tenantList?.length > 0 && (
                <AccountListBox>
                  {tenantList?.map((card, index) => (
                    <AccountList
                      key={index}
                      onClick={() =>
                        handleSelectedAccount(
                          selectedAccount.id !== index,
                          index,
                          card
                        )
                      }
                      isActive={selectedAccount.id === index}
                    >
                      <LHSBox>
                        {card.CustomerName && (
                          <AccountTitle variant="textSM" weight="l500">
                            {card.CustomerName}
                          </AccountTitle>
                        )}
                        {card.TenantName && (
                          <AccountSubTitle variant="textSM" weight="l400">
                            {card.TenantName}
                          </AccountSubTitle>
                        )}
                      </LHSBox>
                      {card.Environment && (
                        <Badge variant="textXS" weight="l500">
                          {card.env.toLowerCase() === "dev"
                            ? card.Environment.toLowerCase() === "starter"
                              ? "SD"
                              : "ED"
                            : card.Environment}
                        </Badge>
                      )}
                      <ArrowRight icon={"icon-arrow-right"} />
                    </AccountList>
                  ))}
                </AccountListBox>
              )}
              {tenantList?.length <= 0 && (
                <EmptyListBox>
                  <EmptyText variant="textSM" weight="l400">
                    No account found
                  </EmptyText>
                </EmptyListBox>
              )}
              <CompanyCodeBox>
                <CompanyHeading variant="textSM" weight="l500">
                  Don't see your account?
                </CompanyHeading>
                <CompanySubHeading variant="textSM" weight="l400">
                  Please enter Company Code provided by your organization
                  through the Invite Email
                </CompanySubHeading>
                <StyledForm
                  layout="vertical"
                  labelCol={{
                    span: 10,
                  }}
                  wrapperCol={{
                    span: 24,
                  }}
                  name="control-hooks-code"
                  onFinish={handleSubmit}
                  requiredMark={false}
                  size={"large"}
                  form={form}
                >
                  <StyledForm.Item
                    name="code"
                    rules={[
                      {
                        type: "text",
                        message: "Company Code is not a valid code!",
                      },
                      {
                        required: true,
                        message: "Company Code is required",
                      },
                    ]}
                  >
                    <FormInput placeholder="Enter Company Code" />
                  </StyledForm.Item>
                  <FormItem>
                    <SignInBtn
                      buttonType="submit"
                      variant="primary"
                      btnLabel="Apply"
                    />
                  </FormItem>
                </StyledForm>
              </CompanyCodeBox>
            </>
          )}
        </BodyContent>
      </Container>
    </LoginWrapper>
  );
}

export default Login;

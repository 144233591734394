import React, { Fragment, useEffect } from "react";
import useScript from "../useScript";

const GoogleSSOLogin = ({
  onGoogleSignIn = () => {},
  onError = () => {},
  text = "Continue with Google",
}) => {
  const script = useScript("https://accounts.google.com/gsi/client");

  const scriptFlag = "__googleOneTapScript__";
  const handleCredentialResponse = (response) => {
    console.log(response);
    if (response.credential) {
      onGoogleSignIn(response.credential);

      window.parent.postMessage(
        {
          sender: "googleLogin",
          message: response.credential,
          token: response.credential,
        },
        "*"
      );
    }
  };

  useEffect(() => {
    const selectedAc = JSON.parse(localStorage.getItem("selectedAccount"));
    const initializeGoogleSSO = () => {
      try {
        if (window.google && script === "ready") {
          const clientId =
            selectedAc?.env === "prod"
              ? process.env.REACT_APP_Google_APP_CLIENT_ID_PROD
              : process.env.REACT_APP_Google_APP_CLIENT_ID_DEV_UAT;

          window.google.accounts.id.initialize({
            client_id: clientId,
            callback: handleCredentialResponse,
          });
          window[scriptFlag] = true;
          var el = document.getElementById("g_id_onload");
          if (el) {
            window.google.accounts.id.renderButton(el, {
              type: "standard",
              shape: "rectangular",
              theme: "outline",
              text: "continue_with",
              size: "large",
              logo_alignment: "center",
              width: 360,
            });
          }
        }
      } catch (err) {
        console.error("Error initializing Google SSO:", err);
        onError(err);
      }
    };

    if (script === "ready") {
      initializeGoogleSSO();
    }

    return () => {
      if (window.google && window[scriptFlag]) {
        window.google.accounts.id.disableAutoSelect();
      }
    };
  }, [script]);

  return (
    <Fragment>
      <div id="g_id_onload" className="sso-login-box google"></div>
    </Fragment>
  );
};

export default GoogleSSOLogin;

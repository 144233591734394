import React, { useEffect, useState } from "react";
import {
  LoginWrapper,
  Container,
  StyledForm,
  BodyContent,
  ImgLogo,
  ImageHolder,
  FormHeading,
  FormHeadingBox,
  FormSubHeading,
  FormInput,
  SignInBtn,
  // SignUpText,
  // SignUpLink,
  FormItem,
  ForgotPassword,
  LoginOptionBox,
  LoginBtnItem,
} from "./style";
import iconLogo from "assets/images/website-logo.svg";
import Separator from "components/shared/separator";
import { tostor } from "components/helpers";
import { useNavigate } from "react-router-dom";
import AzureAuthenticationButton from "containers/Signup/azure/azure-authentication-component";
import { isValidWorkEmail } from "utils";
import { isValidEmail, isValidPassword } from "utils/validators";
import { tenantService } from "services/tenantService";
import GoogleSSOLogin from "containers/Signup/google/googleLogin";

function TenantLogin() {
  let navigate = useNavigate();
  const [form] = StyledForm.useForm();
  const [selectedAccount, setSelectedAccount] = useState({});
  const screen = 0;

  const handleSubmit = (values) => {
    if (screen === 0) {
      let data = {
        TenantName: selectedAccount?.TenantName,
        UserName: values.Email,
        Password: values.password,
      };
      tenantService
        .login(data, selectedAccount?.AdminApiRootDomain)
        .then((res) => {
          let tenantName = selectedAccount?.TenantName;
          let token = res?.cognitoToken?.AuthenticationResult?.IdToken;
          let oloid = res?.user?.OloID;
          let loginURl = selectedAccount?.AdminApiRootDomain.replace(
            "api",
            tenantName
          );
          window.location.href = `${loginURl}/login?token=${token}&oloid=${oloid}`;
        })
        .catch((error) => {
          tostor.error(error.message);
        });
    }
  };
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("selectedAccount")) === null) {
      navigate("/login");
    } else if (JSON.parse(localStorage.getItem("selectedAccount")) !== null) {
      setSelectedAccount(JSON.parse(localStorage.getItem("selectedAccount")));
      form.setFieldValue(
        "Email",
        JSON.parse(localStorage.getItem("selectedAccount"))?.emailId || ""
      );
    }
  }, []);
  const GetEmailField = () => (
    <StyledForm.Item
      name="Email"
      label="Email"
      validateTrigger={["onSubmit"]}
      rules={[
        {
          required: true,
          message: "Email is required",
        },
        {
          validator: (_, value) => {
            if (!value || (isValidEmail(value) && isValidWorkEmail(value))) {
              return Promise.resolve();
            } else if (!isValidWorkEmail(value)) {
              return Promise.reject("Please enter work email");
            } else {
              return Promise.reject("Email is not a valid email!");
            }
          },
        },
      ]}
      hasFeedback
    >
      <FormInput placeholder="Enter your email" />
    </StyledForm.Item>
  );
  const getText = (screen) => {
    switch (screen) {
      case 0:
        return {
          heading: "Log in to your account",
          subHeading: "",
        };
      default:
        return {
          heading: "Log in to your account",
          subHeading: "",
        };
    }
  };

  const data = getText(screen);

  const handleGoogleAuthFailure = (error) => {
    console.log(error);
    tostor.error("Not able to connect to server");
  };

  const handleGoogleLogin = (token) => {
    localStorage.removeItem("user");
    const user = {
      TenantName: selectedAccount?.TenantName,
      Token: token,
    };
    tenantService
      .googleLogin(user, selectedAccount?.AdminApiRootDomain)
      .then((res) => {
        let tenantName = selectedAccount?.TenantName;
        let token = res?.data?.CognitoToken;
        let oloid = res?.data?.User?.OloID;
        let loginURl = selectedAccount?.AdminApiRootDomain.replace(
          "api",
          tenantName
        );
        window.location.href = `${loginURl}/login?token=${token}&oloid=${oloid}`;
      })
      .catch((error) => {
        tostor.error(error.message);
      });
  };

  const onAuthenticated = (userAccountInfo) => {
    localStorage.removeItem("user");
    const user = {
      TenantName: selectedAccount?.TenantName,
      Token: userAccountInfo.idToken,
    };
    tenantService
      .azureLogin(user, selectedAccount?.AdminApiRootDomain)
      .then((res) => {
        let tenantName = selectedAccount?.TenantName;
        let token = res?.data?.CognitoToken;
        let oloid = res?.data?.User?.OloID;
        let loginURl = selectedAccount?.AdminApiRootDomain.replace(
          "api",
          tenantName
        );
        window.location.href = `${loginURl}/login?token=${token}&oloid=${oloid}`;
      })
      .catch((error) => {
        tostor.error(error.message);
      });
  };
  // const SignUpLinkComponent = () => (
  //   <SignUpText variant="textSM" weight="l400" className="text-center">
  //     Don't have an account?{" "}
  //     <SignUpLink
  //       variant="routelink1"
  //       weight="l500"
  //       to={"/"}
  //       className="text-decoration form-footer-subtext"
  //     >
  //       Sign up
  //     </SignUpLink>
  //   </SignUpText>
  // );
  const generateParams = () => {
    let resetData = {};
    let emailId = form.getFieldValue("Email");

    localStorage.removeItem("resetData");
    if (JSON.parse(localStorage.getItem("selectedAccount")) !== null) {
      if (
        JSON.parse(localStorage.getItem("selectedAccount")).emailId !== emailId
      ) {
        resetData = {
          email: emailId,
        };
      } else {
        resetData = {
          email: emailId,
        };
      }
    }
    localStorage.setItem("resetData", JSON.stringify(resetData));
    navigate("/reset-password");
  };

  return (
    <LoginWrapper>
      <Container>
        <BodyContent>
          <ImageHolder>
            <ImgLogo src={iconLogo} />
          </ImageHolder>
          <FormHeadingBox>
            {data?.heading && (
              <FormHeading weight="a900" variant="displaySM">
                {data?.heading}
              </FormHeading>
            )}
            {data?.subHeading && (
              <FormSubHeading variant="textMD" weight="l400">
                {data?.subHeading}
              </FormSubHeading>
            )}
          </FormHeadingBox>

          {screen === 0 && (
            <>
              <StyledForm
                layout="vertical"
                labelCol={{
                  span: 10,
                }}
                wrapperCol={{
                  span: 24,
                }}
                name="control-hooks-sign"
                onFinish={handleSubmit}
                requiredMark={false}
                size="large"
                form={form}
              >
                <GetEmailField />
                <FormItem position="relative" mb="0px">
                  <StyledForm.Item
                    name="password"
                    label="Password"
                    validateTrigger={["onSubmit"]}
                    rules={[
                      {
                        required: true,
                        message: "Password is required",
                      },
                      {
                        validator: (_, value) => {
                          if (!value || value.length >= 8) {
                            return Promise.resolve();
                          } else if (isValidPassword(value)) {
                            return Promise.reject(
                              "Must be at least 8 characters."
                            );
                          } else {
                            return Promise.reject("Password is required!");
                          }
                        },
                      },
                    ]}
                    hasFeedback
                  >
                    <FormInput.Password placeholder="Password" />
                  </StyledForm.Item>
                  <ForgotPassword
                    variant="span"
                    fontSize={["10px", "12px", "14px"]}
                    onClick={generateParams}
                    weight="l500"
                  >
                    Forgot password?
                  </ForgotPassword>
                </FormItem>
                <FormItem>
                  <SignInBtn
                    buttonType="submit"
                    variant="primary"
                    btnLabel="Login"
                  />
                </FormItem>
              </StyledForm>
              <Separator />
              <LoginOptionBox>
                <LoginBtnItem>
                  <GoogleSSOLogin
                    onGoogleSignIn={(credential) =>
                      handleGoogleLogin(credential)
                    }
                  />
                </LoginBtnItem>
                <LoginBtnItem>
                  <AzureAuthenticationButton
                    onAuthenticated={onAuthenticated}
                    btnLabel="Continue with Microsoft"
                  />
                </LoginBtnItem>
              </LoginOptionBox>
              {/* <SignUpLinkComponent /> */}
            </>
          )}
        </BodyContent>
      </Container>
    </LoginWrapper>
  );
}

export default TenantLogin;
